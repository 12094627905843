import React from 'react';

type Props = {}

// TODO: implement this and also add to movie detail row
export function EsFetchFailedRow(props: Props) {
  return (
    <div>EsFetchFailedRow</div>
  );
}
